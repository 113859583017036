<template>
  <span>
    <span v-if="readonly" :style="'font-weight: bold; color: ' + colour + ';'" :id="id + '-' + code" @mouseover="show = true" @mouseleave="show = false">{{code}}</span>
    <a href="#" v-if="!readonly" :style="'font-weight: bold; color: ' + colour + ';'" :id="id + '-' + code" @click.prevent="toggle" @mouseover="show = true" @mouseleave="show = false">{{code}}</a>
    &nbsp;<b-tooltip :target="id + '-' + code" :show.sync="show" triggers v-if="description">{{description}}</b-tooltip>
  </span>
</template>

<script>
const uniqueId = require('lodash.uniqueid')

export default {
  name: 'Flag',
  props: { value:        { type: Boolean, required: true  },
           code:         { type: String,  required: true  },
           activeColour: { type: String,  required: true  },
           description:  { type: String,  default:  null  },
           readonly:     { type: Boolean, default:  false }
         },
  data() {
    return {
      id: null,
      content: this.value,
      show: false
    }
  },
  computed: {
    colour() {
      return (this.content)?this.activeColour:'#EEEEEE';
    }
  },
  methods: {
    toggle() {
      this.content = !this.content;
      this.show = false;
      this.$emit('input', this.content);
      return false;
    }
  },
  mounted() {
    this.id = uniqueId()
  }
}
</script>
