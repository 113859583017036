<template>
  <span class="recipe-flags">
    <Flag v-if="!readonly || content.paleo"                activeColour="#09487D" :value="content.paleo"               code="P"   :readonly="readonly" @input="(e) => handle('paleo', e)"               description="Paleo" />
    <Flag v-if="!readonly || content.freezable"            activeColour="#22B0F0" :value="content.freezable"           code="F"   :readonly="readonly" @input="(e) => handle('freezable', e)"           description="Freezable" />
    <Flag v-if="!readonly || content.quick"                activeColour="#C22D20" :value="content.quick"               code="Q"   :readonly="readonly" @input="(e) => handle('quick', e)"               description="Quick (less than 45 minutes)" />
    <Flag v-if="!readonly || content.microwavable"         activeColour="#9860DB" :value="content.microwavable"        code="M"   :readonly="readonly" @input="(e) => handle('microwavable', e)"        description="Microwavable" />
    <Flag v-if="!readonly || content.vegetarian"           activeColour="#738954" :value="content.vegetarian"          code="V"   :readonly="readonly" @input="(e) => handle('vegetarian', e)"          description="Vegetarian" />
    <Flag v-if="!readonly || content.glutenFree"           activeColour="#C49C2E" :value="content.glutenFree"          code="GF"  :readonly="readonly" @input="(e) => handle('glutenFree', e)"          description="Gluten Free" />
    <Flag v-if="!readonly || content.glutenFreeAdapatable" activeColour="#5F6360" :value="content.glutenFreeAdaptable" code="GFA" :readonly="readonly" @input="(e) => handle('glutenFreeAdaptable', e)" description="Gluten Free Adaptable - All ingredients are either Gluten Free or available as Gluten Free" />
  </span>
</template>

<script>
import Flag from '@/components/Flag.vue';
import Vue from 'vue';

export default {
  name: 'RecipeFlags',
  props: { value:    { type: Object, required: true },
           readonly: { type: Boolean, default: false }
         },
  data() {
    return {
      content: Object.assign({}, {paleo: false, freezable: false, quick: false, microwavable: false, vegetarian: false, glutenFree: false, glutenFreeAdaptable: false}, this.value)
    }
  },
  components: {
    Flag,
  },
  methods: {
    handle(attr, newVal) {
      Vue.set(this.content, attr, newVal);
      this.$emit('input', this.content);
    }
  }
}
</script>
